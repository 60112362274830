.quiz-container {
  contain: content;
  position: relative;
  margin: 30px auto;
  width: 480px;
  max-width: 100%;
  border-radius: 6px;
}

.quiz-stage {
  contain: content;
  overflow: hidden;
}
/*# sourceMappingURL=index.805d2702.css.map */
